// ContactUs.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import NavigationBar from './NavigationBar'; // Adjust the path according to your file structure
import theme from './theme';

const ContactUs = () => {
  const iframeStyle = {
    width: '100%', // Use 100% width for responsiveness
    maxWidth: '700px', // Max width can be adjusted to your preference
    height: '800px',
    border: 'none',
    marginHeight: '0',
    marginWidth: '0',
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingTop: '10vh' }}>
        <NavigationBar />

        <div style={{ padding: '0rem', textAlign: 'center', maxHeight: '80vh', overflow: 'hidden' }}>
          <iframe 
            src="https://docs.google.com/forms/d/e/1FAIpQLScJSJIq67zPjDjhfcbrOG66KAc9fSbjKOwXFQ_xPzoYGBU_jg/viewform?embedded=true" 
            style={iframeStyle}
            title="Contact Us Form">
            Loading…
          </iframe>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ContactUs;
