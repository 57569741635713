import React from 'react';
import { Box, Container, Typography, Grid, Paper } from '@mui/material';
import { useInView } from 'react-intersection-observer';


const useCases = [
    {
      title: 'Learn how users engage with your chatbot.',
      description: 'Our conversation inspector and analytics dashboard lets our customers refine their freemium OpenAI wrapper, increasing conversion rates and enhancing user experience.'
    },
    {
      title: 'Optimize chatbot engagement and maximize your payouts.',
      description: 'Insights from LlamaLytics show developers on OpenAI\'s GPT store how to optimize their GPT to improve user engagement and satisfaction, resulting in a higher revenue share from OpenAI.'
    },
    {
      title: 'Stop guessing about client chatbot performance.',
      description: 'Monitor the performance of client chatbot applications and make data-backed and informed decisions on how to improve utility and functionality.'
    },
    {
      title: 'Roll out the red carpet for your creators.',
      description: 'Chatbot platforms like Character.ai or OpenAI\'s GPT Store can elevate platform-wide chatbot performance by integrating LlamaLytics directly into their platform, giving their creators the tools they need to build better chatbots.'
    },
  ];
  

  const UseCaseCard = ({ title, description }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.9, // Adjust as needed
    });
  
    return (
      <Grid item xs={12} ref={ref}>
        <Paper 
          elevation={0} 
          sx={{ 
            padding: 2, 
            minHeight: 90, 
            opacity: inView ? 1 : 0, 
            transform: inView ? 'translateY(0)' : 'translateY(20px)', 
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out' 
          }}
        >
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          <Typography>
            {description}
          </Typography>
        </Paper>
      </Grid>
    );
  };
  
  
  const UseCasesSection = () => {
    return (
      <Box sx={{ bgcolor: 'background.paper', pt: 6, pb: 4, margin:'auto', mb:'10vh' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" color="text.primary" gutterBottom sx={{ mb: { xs: '3vh', md: '8vh' }}}>
            Use Cases
          </Typography>
          <Grid container spacing={4} direction="column">
            {useCases.map((useCase, index) => (
              <UseCaseCard key={index} title={useCase.title} description={useCase.description} />
            ))}
          </Grid>
        </Container>
      </Box>
    );
  };
  

export default UseCasesSection;
