import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import NavigationBar from './NavigationBar'; // Adjust the path 
import Footer from './Footer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: "What is LlamaLytics?",
    answer: "LlamaLytics is an advanced analytics tool designed to improve the performance of chatbots. We provide insights from real user interactions, AI-powered suggestions, and comprehensive metrics to enhance engagement, conversion rates, and user satisfaction."
  },
  {
    question: "Why do I need analytics for my chatbot?",
    answer: "Chatbot analytics are essential for understanding user behavior and chatbot performance. Without chatbot analytics, you will miss out on valuable opportunities to refine your chatbot and meet your business goals. By analyzing user interactions, you can identify areas for improvement, optimize user engagement, test new features, and enhance the overall user experience. LlamaLytics provides the tools and insights you need to make data-driven decisions and improve your chatbot. "
  },
  {
    question: "How does LlamaLytics improve chatbot performance?",
    answer: "Our platform offers a conversation inspector and analytics dashboard that helps you understand how users engage with your chatbot. You can finally answer questions like 'when do users leave conversations?' or 'what do users expect from our chatbot?' Additionally, we provide actionable insights and AI-driven recommendations to refine your chatbot, helping you meet your business goals."
  },
  {
    question: "Is LlamaLytics compatible with my existing chatbot infrastructure?",
    answer: "LlamaLytics is designed to integrate effortlessly into your current stack via our API. Additionally, our solution is compatible with OpenAI's GPT store via the 'Actions' interface."
  },
  {
    question: "Can I access real user-chatbot conversations for analysis?",
    answer: "Absolutely. LlamaLytics provides access to real user-chatbot conversation histories via our conversation inspector. You can browse through user conversations to gain a deeper understanding of user interactions and chatbot performance."
  },
  {
    question: "What kind of metrics and analytics does LlamaLytics offer?",
    answer: "In addition to basic metrics like average conversation length and bounce rate (the percentage of users who leave a conversation after a single interaction), LlamaLytics provides advanced analytics, such as user sentiment at conversation end, chatbot response verbosity, and user engagement over the course of a conversation. These metrics help you understand user behavior and chatbot performance more comprehensively."
  },
  {
    question: "How can LlamaLytics benefit chatbot platforms like Character.ai or OpenAI's GPT Store?",
    answer: "By integrating LlamaLytics, chatbot platforms can provide their creators with powerful tools to build better chatbots. This integration can elevate platform-wide performance and user satisfaction. If you would like to learn more about how LlamaLytics can benefit your platform, please contact us for a consultation."
  },
  {
    question: "How does LlamaLytics ensure user privacy?",
    answer: "User privacy is a top priority for us. LlamaLytics employs stringent data privacy protocols to ensure that all user information is handled safely and responsibly. We detect and redact all PII (personally identifiable information) and other sensitive data from user messages and chatbot responses. Examples of PII include social security numbers, phone numbers, and addresses. We also provide a secure environment for data storage and processing, ensuring that user privacy is never compromised."
  },
  {
    question: "What is Enterprise Data Shield?",
    answer: "Enterprise Data Shield is a premium feature designed for enterprise clients to ensure the utmost privacy. When activated, it guarantees that chatbot interactions linked to your account are not used to train our AI suggestion engine. This feature provides an added layer of data security and privacy, giving Enterprise customers more rigid control over their data and its usage."
  },
  {
    question: "How can I get started with LlamaLytics?",
    answer: "To begin using LlamaLytics, you can sign up for a call with our team. We'll guide you through the process and answer any further questions you might have."
  },
  {
    question: "Who can I contact for more information?",
    answer: "For more information, please visit our Contact Us page. Our team is ready to assist you with any inquiries."
  }
];

const FAQ = () => {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ width: '100%', marginTop: '10vh', padding: '5vh' }}>
          <NavigationBar />
          <div>
            <Typography variant="h4" gutterBottom>
              Frequently Asked Questions
            </Typography>
            {faqData.map((faq, index) => (
              <Accordion key={index} sx={{
                // Change the border color
                border: `1px solid ${theme.palette.text.primary}`
              }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ 
                    // Change the color of the expand icon
                    color: theme.palette.text.primary 
                  }} />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  sx={{
                    // Additional styling for the Accordion summary if needed
                  }}
                >
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <Footer />
        </Box>
      </ThemeProvider>
    );
  };
  

export default FAQ;
