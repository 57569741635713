import React from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import Animation from './Animation';
import './Hero.css';

const HeroSection = () => {
  const handleButtonClick = () => {
    window.open('https://calendly.com/peter-upu/free-llamalytics-consultation', '_blank');
  };

  return (
    <Box 
      sx={{ 
        position: 'relative', // Added for absolute positioning of Animation
        bgcolor: 'background.paper', 
        height: { xs: '50vh', md: '60vh' },
        pt: { xs: 0, md: 0 },
        pb: { xs: 5, md: 0 },
        mt: 10,
        mb: { xs: 0, md: 10},
        display: 'flex', 
        alignItems: 'center', 
        overflow: 'hidden'
      }}
    >
      {/* Positioned Animation */}
      <Box sx={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          // display: { xs: 'none', md: 'block' } 
      }}>
        <Animation />
      </Box>

      {/* Gradient Overlay */}
      <Box sx={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: 'linear-gradient(to top, rgba(18,18,18,1), rgba(18,18,18,0.3), rgba(18,18,18,0.5), rgba(18,18,18,0.5), rgba(18,18,18,0.5))',
          mb: -1,
      }}/>

      <Container maxWidth="lg">
        <Grid className="fadeInUp" container spacing={2} alignItems="center" style={{ position: 'relative', zIndex: 1 }}>
          <Grid item xs={12} md={6}>
            <Typography
              component="h1"
              variant="h2"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Get the data you need to improve your chatbot.
            </Typography>
            <Typography
              variant="h5"
              align="left"
              color="text.secondary"
              paragraph
            >
              Improve engagment, conversion rates, and user satisfaction with real user interactions and AI-powered insights.
            </Typography>
            <Button sx={{ mt: 6, fontWeight: 600, letterSpacing: 0.4, elevation: 100}} variant="contained" color="primary" size="large" onClick={handleButtonClick}>
              Schedule free demo
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* The Animation component is now outside this Grid item */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroSection;
