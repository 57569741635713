import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage'; // Import your LandingPage component
import PricingPage from './PricingPage'; // Assuming you have a PricingPage component
import BlogPosts from './BlogPosts'; // Assuming you have a BlogPosts component
import FAQ from './FAQ'; // Assuming you have a FAQ component
import ContactUs from './ContactUs';
import blogPosts from './blogPostConfig'; // Import the blog posts config

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/pricing" element={<PricingPage />} /> */}
        <Route path="/faq" element={<FAQ />} />
        <Route path="/blog" element={<BlogPosts />} />
        {blogPosts.map(({ path, component: Component }) => (
          <Route key={path} path={`/articles/${path}`} element={<Component />} />
        ))}
        <Route path="/contact" element={<ContactUs />} />
        {/* You can add a 404 Not Found route here if needed */}
      </Routes>
    </Router>
  );
}

export default App;
