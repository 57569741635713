import GPTWrapperGuide from './articles/GPTWrapperGuide';
// Import other blog posts...

const blogPosts = [
  {
    path: 'GPTWrapperGuide',
    component: GPTWrapperGuide,
    title: 'The Solopreneur’s new best friend'
  },
  // Add other blog posts...
];

export default blogPosts;
