import React from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const FinalCTASection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.8, // Adjust as needed
  });

  const handleButtonClick = () => {
    window.open('https://calendly.com/peter-upu/free-llamalytics-consultation', '_blank');
  };

  return (
    <Box
      sx={{
        p: 3,
        mt: 4,
        mb: '10vh',
        textAlign: 'center'
      }}
    >
      <Container maxWidth="md" ref={ref}>
        <Typography 
          variant="h4" 
          component="h2" 
          gutterBottom
          sx={{
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateY(0)' : 'translateY(-20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out'
          }}
        >
          Don't let valuable insights slip through the cracks.
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 3,
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out, transition-delay 0.3s',
            fontWeight: 500
          }}
        >
          Sign up for a call with our team to see how LlamaLytics can help you.
        </Typography>
        <Button 
          sx={{ 
            fontWeight: 600, 
            letterSpacing: 0.4,
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out, transition-delay 0.3s'
          }} 
          variant="contained" 
          color="primary" 
          size="large" 
          onClick={handleButtonClick}
        >
          Schedule free demo
        </Button>
      </Container>
    </Box>
  );
};

export default FinalCTASection;
