import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Container, Typography, Paper, Grid, useTheme, useMediaQuery } from '@mui/material';
import { FaAws, FaMicrosoft } from 'react-icons/fa';
import { TbApi } from "react-icons/tb";
import { SiOpenai, SiFirebase, SiGooglecloud  } from 'react-icons/si';
import './Integrations.css';

const IntegrationCard = ({ icon }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const iconSize = isMobile ? 30 : 50; // 30px on mobile, 50px on larger screens

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper elevation={0} sx={{ padding: 3, textAlign: 'center' }}>
        {React.cloneElement(icon, { size: iconSize })}
        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
          {/* Name would go here */}
        </Typography>
      </Paper>
    </Grid>
  );
};

const integrations = [
  {
    // name: 'GPT Store',
    icon: <SiOpenai size={50} />,
  },
  {
    // name: 'AWS',
    icon: <FaAws size={50} />,
  },
  {
    // name: 'Google Cloud',
    icon: <SiGooglecloud size={50} />,
  },
  {
    // name: 'Firebase',
    icon: <SiFirebase size={50} />,
  },
  {
    // name: 'Azure',
    icon: <FaMicrosoft size={50} />,
  },
  {
    // name: 'API',
    icon: <TbApi size={50} />,
  },
];

const IntegrationsSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    cssEase: 'linear',
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 4, pb: 4, mb:'10vh' }}>
      <Container maxWidth="lg" className="carousel-container">
        <Typography variant="h4" align="center" color="text.primary" gutterBottom>
          Integrations
        </Typography>
        <Slider {...settings}>
          {integrations.map((integration, index) => (
            <div key={index}>
              <IntegrationCard {...integration} />
            </div>
          ))}
        </Slider>
        <div className="carousel-overlay"></div>
      </Container>
    </Box>
  );
};

export default IntegrationsSection;
