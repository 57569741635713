import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { useTheme } from '@mui/material/styles';

const Animation = () => {
  const mountRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    const aspectRatio = window.innerWidth / window.innerHeight;
    const initialWidth = Math.min(mountRef.current.clientWidth, window.innerWidth);
    const initialHeight = initialWidth / aspectRatio;
    renderer.setSize(initialWidth, initialHeight);
    renderer.setClearColor(theme.palette.background.default, 1);
    mountRef.current.appendChild(renderer.domElement);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 1); // Soft white light
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0, 1, 1); // Adjust as needed
    scene.add(directionalLight);

    // Original Plane Geometry and Material
    const geometry = new THREE.PlaneGeometry(5, 5, 50, 50);
    const pointsMaterial = new THREE.PointsMaterial({
      color: theme.palette.primary.secondary,
      size: 0.02 // Adjust the size of the points
    });

    // Creating Points for the first plane
    const planePoints = new THREE.Points(geometry, pointsMaterial);
    const rotationAngle = 5 * Math.PI / 8;
    planePoints.rotation.x = rotationAngle;
    scene.add(planePoints);

    // Duplicate Plane Geometry for the second plane
    const geometry2 = new THREE.PlaneGeometry(5, 5, 100, 100);
    const pointsMaterial2 = new THREE.PointsMaterial({
      color: theme.palette.primary.main, // Purple color
      size: 0.01 // Adjust the size of the points
    });

    // Creating Points for the second plane
    const planePoints2 = new THREE.Points(geometry2, pointsMaterial2);
    planePoints2.rotation.x = rotationAngle;
    planePoints2.position.y -= 0.05; // Adjust this value for offset
    scene.add(planePoints2);

    camera.position.z = 5;

    const clock = new THREE.Clock();

    let scrollAmount = 0;

    const handleScroll = () => {
      const scrollMax = document.documentElement.scrollHeight - window.innerHeight;
      scrollAmount = window.scrollY / scrollMax; // Normalizes scroll amount between 0 and 1
      // Ensure scrollAmount is within 0 and 1
      scrollAmount = Math.min(1, Math.max(0, scrollAmount));
    };

    window.addEventListener('scroll', handleScroll);

    const animate = () => {
      requestAnimationFrame(animate);

      const elapsedTime = clock.getElapsedTime();
      const oscillationFrequency = 0.5; // Adjust frequency as needed

      // Exponential decay parameters
      const initialYOffset = 0.5; // Starting y-offset
      const decayRate = 1; // Adjust the rate as needed
      const normalYOffset = 0.05; // Normal y-offset after decay

      // Calculate current y-offset
      const currentYOffset = initialYOffset * Math.exp(-decayRate * elapsedTime);

      // Update the first plane
      if (planePoints.geometry instanceof THREE.BufferGeometry) {
        const positions = planePoints.geometry.attributes.position;
        const vertex = new THREE.Vector3();

        for (let i = 0; i < positions.count; i++) {
          vertex.fromBufferAttribute(positions, i);
          
          // Ripple effect for the first plane
          const waveAmplitude = 0.1;
          const waveX1 = waveAmplitude * Math.sin(vertex.x * 4 + oscillationFrequency * elapsedTime);
          const waveY1 = waveAmplitude * Math.sin(vertex.y * 3 + elapsedTime);

          vertex.z = waveX1 + waveY1;

          positions.setXYZ(i, vertex.x, vertex.y, vertex.z);
        }

        positions.needsUpdate = true;
      }

      // Update the second plane
      if (planePoints2.geometry instanceof THREE.BufferGeometry) {
        const positions2 = planePoints2.geometry.attributes.position;
        const vertex2 = new THREE.Vector3();

        for (let i = 0; i < positions2.count; i++) {
          vertex2.fromBufferAttribute(positions2, i);
          
          // Ripple effect for the second plane
          const waveAmplitude2 = 0.1;
          const waveX2 = waveAmplitude2 * Math.sin(vertex2.x * 4 + oscillationFrequency * elapsedTime);
          const waveY2 = waveAmplitude2 * Math.sin(vertex2.y * 3 + elapsedTime);

          vertex2.z = waveX2 + waveY2;

          positions2.setXYZ(i, vertex2.x, vertex2.y, vertex2.z);
        }

        positions2.needsUpdate = true;
      }

      // Update plane y-offset based on scroll
      const finalYOffset = Math.max(normalYOffset, currentYOffset);
      planePoints2.position.y = finalYOffset - scrollAmount * (5 + normalYOffset); // Interpolates between -0.05 and -1 based on scroll
      planePoints.position.y = scrollAmount * (3 + normalYOffset); // Interpolates between -0.05 and -1 based on scroll

      renderer.render(scene, camera);
    };

    animate();

    // Cleanup function to remove the event listener and renderer element
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, [theme]); // Add dependencies here if needed

  return (
    <div ref={mountRef} style={{ display: 'flex', justifyContent: 'center', width: '100%', overflow: 'hidden' }} />
  );
  
};

export default Animation;
