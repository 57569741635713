import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import NavigationBar from './NavigationBar'; // Adjust the path according to your file structure
import HeroSection from './Hero';
import VideoSection from './Video';
import UseCasesSection from './UseCases';
import FeaturesSection from './Features';
import IntegrationsSection from './Integrations';
import TestimonialsSection from './Testimonials';
import FinalCTASection from './FinalCTA';
import Footer from './Footer';
import { Box } from '@mui/material';
import ThreeBackground from './ThreeBackground';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        {/* <ThreeBackground /> */}
        <NavigationBar />
        {/* <div style={{ padding: '1rem' }}> */}
        <div>
          <HeroSection />
          {/* <VideoSection /> */}
          <UseCasesSection />
          <FeaturesSection />
          <IntegrationsSection />
          {/* <TestimonialsSection /> */}
          <FinalCTASection />
        </div>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;