import React from 'react';
import { Box, Container, Typography, Grid, Paper, useTheme } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import MetricsIcon from '@mui/icons-material/BarChart';
import AiSuggestionIcon from '@mui/icons-material/AutoFixHigh';
import PrivacyIcon from '@mui/icons-material/Lock';
import CommunityIcon from '@mui/icons-material/Group';
import IntegrationIcon from '@mui/icons-material/Settings';
import { useInView } from 'react-intersection-observer';


const features = [
    { title: 'Conversation Histories', description: 'Access real user-chatbot conversations for deeper understanding.', icon: <ChatIcon /> },
    { title: 'Comprehensive Metrics', description: 'Get actionable, holistic statistics across your chatbot’s performance.', icon: <MetricsIcon /> },
    { title: 'AI-Driven Suggestions', description: 'Receive powerful recommendations for optimizing your chatbot.', icon: <AiSuggestionIcon /> },
    { title: 'Privacy', description: 'Prioritize user safety with our stringent data privacy protocols.', icon: <PrivacyIcon /> },
    { title: 'Integration', description: 'Effortlessly integrate our solution with your existing chatbot infrastructure.', icon: <IntegrationIcon /> },
    { title: 'Community', description: 'Engage with peers in our supportive and growing community.', icon: <CommunityIcon /> },
  ];

  const FeatureCard = ({ title, description, icon }) => {
    const theme = useTheme();
  
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 1, // Adjust the threshold as needed
    });
  
    return (
      <Grid item xs={12} sm={6} md={4} ref={ref}>
        <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(20px)', transition: 'opacity 0.8s ease-out, transform 0.8s ease-out' }}>
          {React.cloneElement(icon, { style: theme.customStyles.responsiveIcons })}
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          <Typography>
            {description}
          </Typography>
        </Paper>
      </Grid>
    );
  };
  
  

const FeaturesSection = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 4, pb: 4, margin:'auto', mb:'10vh' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" color="text.primary" gutterBottom 
        sx={{
          mb: { xs: '3vh', md: '8vh' },
          }}>
          Features
        </Typography>
        <Grid container spacing={2}>
          {features.map((feature, index) => (
            <FeatureCard key={index} title={feature.title} description={feature.description} icon={feature.icon} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturesSection;
