import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './logo1.svg'; // Ensure this path is correct for your logo

// Skeleton placeholder for navigation links
const NavigationSkeleton = () => (
  <Box sx={{ width: 250 }}>
    <List>
      {[1, 2, 3].map((index) => (
        <ListItem button key={index} sx={{ marginBottom: 1 }}>
          <ListItemText primary={<Skeleton variant="text" />} />
        </ListItem>
      ))}
    </List>
    <Divider />
    {/* Additional skeleton components can go here */}
  </Box>
);

const NavigationBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'FAQ', path: '/faq' },
    // { title: 'Pricing', path: '/pricing' },
    // { title: 'Blog', path: '/blog' },
    { title: 'Contact Us', path: '/contact' }
  ];

  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        {navLinks.map((link, index) => (
          <ListItem button key={link.title} component="a" href={link.path}>
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      {/* Additional links or components can go here */}
    </Box>
  );

  return (
    <AppBar position="fixed" elevation={0} sx={{ marginBottom: 10, backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText, padding: 1 }}>
      <Toolbar>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, zIndex: 1 }} // Ensure the menu icon is always visible
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img src={logo} alt="Logo" style={{ marginRight: '10px', width: '20px'}}/>
          <Typography variant="h6" component="div">
            LlamaLytics
          </Typography>
        </Box>
        {!isMobile && (
          <Box sx={{ position: 'absolute', left: 0, right: 0, textAlign: 'center' }}>
            {navLinks.map((link) => (
              <Typography 
                key={link.title} 
                component="a" 
                href={link.path} 
                sx={{
                  fontSize: {
                    xs: 'default',
                    md: '18px'
                  },
                  color: 'inherit', // Inherits color from parent
                  marginRight: theme.spacing(3), // Adjust spacing as needed
                  textDecoration: 'none', // Removes underline from links
                  '&:hover': {
                    textDecoration: 'underline', // Optional: underline on hover
                  },
                }}
              >
                {link.title}
              </Typography>
            ))}
          </Box>
        )}
        {isMobile && (
          <Drawer
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            {/* Render skeleton placeholder if navLinks are not available yet */}
            {navLinks.length ? drawer : <NavigationSkeleton />}
          </Drawer>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
