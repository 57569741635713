import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'primary.default', color: 'white', py: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
          
          <Grid item>
            <Link href="articles/GPTWrapperGuide" color="inherit" underline="hover">
              How to build a chatbot in 2024
            </Link>
          </Grid>

          <Grid item>
            <IconButton color="inherit" href="https://twitter.com/getllamalytics" target="_blank">
              <TwitterIcon />
            </IconButton>
          </Grid>

          <Grid item>
            <Typography variant="body2" color="inherit">
              &copy; {new Date().getFullYear()} LlamaLytics. All Rights Reserved.
            </Typography>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
