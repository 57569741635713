import { createTheme } from '@mui/material/styles';

// Function to make typography responsive
function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (max-width:600px)': {
      fontSize: sm,
    },
    '@media (min-width:600px)': {
      fontSize: md,
    },
    '@media (min-width:960px)': {
      fontSize: lg,
    },
  };
}

// Function to define responsive icon sizes
const responsiveIconSizes = {
  '@media (max-width:600px)': {
    fontSize: '1.5rem', // Smaller size for small screens
  },
  '@media (min-width:601px) and (max-width:960px)': {
    fontSize: '2rem', // Medium size for medium screens
  },
  '@media (min-width:961px)': {
    fontSize: '2.5rem', // Default size for larger screens
  },
};

// Create and export the custom theme
const backgroundColor = 'rgba(18, 18, 18, 1)'; //'rgba(18,18,18,0)';
const theme = createTheme({
  palette: {
    primary: {
      main: '#5D2FE8',
    },
    secondary: {
      main: backgroundColor,
    },
    error: {
      main: '#ff1744',
    },
    background: {
      default: backgroundColor,
      paper: backgroundColor,
    },
    text: {
      primary: '#FFFFFF', // Set primary text color to white
      secondary: '#FFFFFF', // Optionally, set secondary text color to a lighter shade
    },
  },
  typography: {
    fontFamily: '"Montserrat"',
    h1: {
      fontWeight: 500,
      ...responsiveFontSizes({ sm: '1.5rem', md: '2.25rem', lg: '2.75rem' }),
    },
    h2: {
      fontWeight: 600,
      ...responsiveFontSizes({ sm: '1.5rem', md: '2rem', lg: '2.5rem' }),
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontWeight: 500,
      ...responsiveFontSizes({ sm: '1.125rem', md: '1.75rem', lg: '2.25rem' }),
      letterSpacing: '0em',
    },
    h4: {
      fontWeight: 550,
      ...responsiveFontSizes({ sm: '1.25rem', md: '1.5rem', lg: '2rem' }),
      letterSpacing: '0.00735em',
    },
    h5: {
      fontWeight: 500,
      ...responsiveFontSizes({ sm: '1rem', md: '1.25rem', lg: '1.75rem' }),
      letterSpacing: '0em',
    },
    h6: {
      fontWeight: 1000,
      ...responsiveFontSizes({ sm: '1rem', md: '1rem', lg: '1.25rem' }),
      letterSpacing: '0.0075em',
    },
    p: {
      ...responsiveFontSizes({ sm: '0.6rem', md: '0.9rem', lg: '1rem' }),
      letterSpacing: '0.00938em',
    },
  },
  customStyles: {
    responsiveIcons: responsiveIconSizes,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
          padding: '12px 16px',
          fontSize: {
            xs: '0.8rem',
            sm: '0.9rem',
            md: '1rem',
          },
          backgroundImage: 'linear-gradient(90deg, #8333EA, #3B2CE6)', // Gradient color
          color: 'white', // Text color
          // transition: 'background-image 0.5s ease-in-out', // Transition effect
          '@media (max-width:600px)': {
            padding: '8px 12px',
            fontSize: '12px',
          },
          '&:hover': {
            backgroundImage: 'linear-gradient(90deg, #8333EA, #8333EA)', // Gradient color
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          src: local('Montserrat'), url('<URL_TO_MONTSERRAT_FONT>') format('woff2');
          font-display: block;
        }
      `,
    },
    // MuiContainer: {
    //   styleOverrides: {
    //     root: {
    //         padding: 1,
    //     },
    //   },
    // },
    // Add other component overrides if needed
  },
});

export default theme;
