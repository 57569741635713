import React from 'react';
import { ThemeProvider, CssBaseline, Box, Typography, Link } from '@mui/material';
import theme from './theme';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import blogPosts from './blogPostConfig'; // Import the blog posts config

const BlogPosts = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavigationBar />
      <Box style={{ padding: '2rem' }}>
        <Typography variant="h1" style={{ marginTop: '10vh' }}gutterBottom>Blog Posts</Typography>
        <Box style={{ marginTop: '1rem' }}>
          {blogPosts.map(({ path, title }) => (
            <Box key={path} mb={2}>
              <Link href={`/articles/${path}`}>{title}</Link>
            </Box>
          ))}
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export default BlogPosts;
